import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IProps {
  title: string;
  value: string;
  bgColor: string;
}

export default function StatsCard(props: IProps) {
  const { title, value, bgColor } = props;
  const { t } = useTranslation();
  return (
    <Flex
      bg={bgColor}
      p="8px 12px"
      borderRadius="16px"
      gap={{ base: '1px', md: '8px' }}
      flex="1"
      flexDirection="column"
      overflow="hidden">
      <Text variant="statsCardTitle" noOfLines={1}>
        {t(title)}
      </Text>
      <Text variant="statsCardValue" noOfLines={1}>
        {t(value)}
      </Text>
    </Flex>
  );
}
