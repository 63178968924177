import { useQuery } from '@tanstack/react-query';
import { SharedWithMeFileMedia } from 'services/@types';
import FileService from 'services/files.api';

const useSharedWithMeFiles = () => {
  const { data: files } = useQuery({
    queryKey: ['sharedWithMeFiles'],
    queryFn: async () => {
      const data = await FileService.getFiles<SharedWithMeFileMedia[]>({
        sharedWithMe: 'true',
      });
      return data;
    },
    staleTime: 1000 * 60 * 1, // 1 minutes
  });

  return { files: files || [] };
};

export default useSharedWithMeFiles;
