import { formatNumber } from 'helpers/formatNumber';
import { IQuote, IQuoteProductListItem, Product } from 'services/@types';
import { EXCHANGE_RATE_FROM_ILS_TO_USD } from 'variables/currency';

export const calculateTotalVat = (products: IQuoteProductListItem[]) => {
  const totalVat = products.reduce((acc, curr) => {
    if (curr.vat) {
      return formatNumber(acc + curr.vat);
    }
    return acc;
  }, 0);

  return totalVat;
};

export const calculateTotalPayment = (
  products: IQuoteProductListItem[],
  totalIncludingDiscount: number,
) => {
  const totalPayment = products.reduce((acc, curr) => {
    if (curr.gross) {
      return formatNumber(acc + curr.gross);
    }
    return acc;
  }, 0);

  if (totalIncludingDiscount) {
    const totalVat = calculateTotalVat(products);
    return formatNumber(totalIncludingDiscount - totalVat);
  }

  return totalPayment;
};

export const calculateTotalIncludingDiscount = (
  discountType: IQuote['generalPaymentDetails']['discountType'],
  discount: IQuote['generalPaymentDetails']['discount'],
  total: IQuote['generalPaymentDetails']['total'],
) => {
  if (discountType === 'fixed') {
    return formatNumber(total - discount);
  }
  return formatNumber(total - (total * discount) / 100);
};

export const calculateTotal = (products: IQuoteProductListItem[]) => {
  return products.reduce((acc, curr) => {
    if (curr.net) {
      return formatNumber(acc + curr.net);
    }
    return acc;
  }, 0);
};

// get updated product with vat included or not
export const getUpdatedProduct = (
  product: IQuoteProductListItem,
  isVatIncluded: boolean,
  vatValue: number,
) => {
  if (isVatIncluded) {
    return {
      ...product,
      isVatIncluded,
      vat: formatNumber(product.net * vatValue),
      gross: formatNumber(product.net + product.net * vatValue),
    };
  }

  return {
    ...product,
    isVatIncluded,
    vat: 0,
    gross: product.net,
  };
};

// calculate products vat included or not by index
export const calculateProductsVatByIndex = (
  products: IQuoteProductListItem[],
  index: number,
  isVatIncluded: boolean,
  vatValue: number,
) => {
  return products.map((p, key) =>
    key === index ? getUpdatedProduct(p, isVatIncluded, vatValue) : p,
  );
};

// calculate products vat included or not
export const calculateProductsVat = (
  products: IQuoteProductListItem[],
  isVatIncluded: boolean,
  vatValue: number,
) => {
  return products.map((p) => getUpdatedProduct(p, isVatIncluded, vatValue));
};

// add selected product to products array
export const addSelectedProduct = (
  products: IQuoteProductListItem[],
  selectedProduct: Product,
  index: number,
  vatValue: number,
) => {
  return products.map((p, key) =>
    key === index
      ? {
          ...p,
          description: selectedProduct.name,
          productId: selectedProduct.id,
          amount: selectedProduct.price,
          quantity: 1,
          net: selectedProduct.price,
          vat: formatNumber(selectedProduct.price * vatValue),
          gross: formatNumber(
            selectedProduct.price * vatValue + selectedProduct.price,
          ),
          isVatIncluded: true,
        }
      : p,
  );
};

// get updated product by quantity
export const getUpdatedProductByQuantity = (
  product: IQuoteProductListItem,
  quantity: number,
  vatValue: number,
) => {
  return {
    ...product,
    quantity,
    net: formatNumber(product.amount * quantity),
    vat: product.isVatIncluded
      ? formatNumber(formatNumber(product.amount * quantity) * vatValue)
      : 0,
    gross: product.isVatIncluded
      ? formatNumber(
          formatNumber(product.amount * quantity) +
            formatNumber(product.amount * quantity) * vatValue,
        )
      : formatNumber(product.amount * quantity),
  };
};

// get updated product by amount
export const getUpdatedProductByAmount = (
  product: IQuoteProductListItem,
  amount: number,
  vatValue: number,
) => {
  return {
    ...product,
    amount,
    net: formatNumber(product.quantity * amount),
    vat: product.isVatIncluded
      ? formatNumber(formatNumber(product.quantity * amount) * vatValue)
      : 0,
    gross: product.isVatIncluded
      ? formatNumber(
          formatNumber(product.quantity * amount) +
            formatNumber(product.quantity * amount) * vatValue,
        )
      : formatNumber(product.quantity * amount),
  };
};

// get updated product by usd
export const getUpdatedProductByUsd = (
  product: IQuoteProductListItem,
  price: number,
  vatValue: number,
) => {
  return {
    ...product,
    amount: formatNumber(price * EXCHANGE_RATE_FROM_ILS_TO_USD),
    net: formatNumber(price * EXCHANGE_RATE_FROM_ILS_TO_USD * product.quantity),
    vat: formatNumber(
      price * EXCHANGE_RATE_FROM_ILS_TO_USD * product.quantity * vatValue,
    ),
    gross: formatNumber(
      price * EXCHANGE_RATE_FROM_ILS_TO_USD * product.quantity * vatValue +
        price * EXCHANGE_RATE_FROM_ILS_TO_USD * product.quantity,
    ),
  };
};

// get updated product by ils
export const getUpdatedProductByIls = (
  product: IQuoteProductListItem,
  price: number,
  vatValue: number,
) => {
  return {
    ...product,
    amount: formatNumber(price),
    net: formatNumber(price * product.quantity),
    vat: formatNumber(price * product.quantity * vatValue),
    gross: formatNumber(
      price * product.quantity * vatValue + price * product.quantity,
    ),
  };
};
