import { setBusinessEventPlanFilter } from 'contexts/redux/businessEventPlan/businessEventPlanSlice';
import { RootState } from 'contexts/redux/store';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export default function useEventFilter(isArchive: boolean) {
  const dispatch = useDispatch<any>();
  const { filter } = useSelector((state: RootState) => state.businessEventPlan);
  const { i18n } = useTranslation();

  const options = useMemo(
    () =>
      Array.from({ length: 10 * 12 }, (_, i) =>
        moment('2024-01-01')
          .add(i, 'months')
          .locale(i18n.language)
          .format('MMMM YYYY'),
      ),
    [i18n.language],
  );

  const eventName = useMemo(() => filter.name, [filter.name]);

  const selectedEventType = useMemo(
    () => (filter.type ? [`event_types.${filter.type}`] : []),
    [filter.type],
  );

  const selectedMonth = useMemo(() => {
    if (filter.month) {
      return [filter.month];
    }
    return [];
  }, [filter.month]);

  const showedMonth = useMemo(
    () =>
      selectedMonth[0]
        ? moment(selectedMonth[0]).locale(i18n.language).format('MMMM YYYY')
        : '',
    [selectedMonth, i18n.language],
  );

  const handleEventNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      dispatch(setBusinessEventPlanFilter({ ...filter, name: value }));
    },
    [dispatch, filter],
  );

  const handleChangeEventType = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_types.', '');
      if (filter.type === formattedSelected) {
        dispatch(setBusinessEventPlanFilter({ ...filter, type: '' }));
        return;
      }
      dispatch(
        setBusinessEventPlanFilter({ ...filter, type: formattedSelected }),
      );
    },
    [filter, dispatch],
  );

  const handleChangeEventStatus = useCallback(
    ({ id, label }: { id: string; label: string }) => {
      if (filter.status.includes(id)) {
        dispatch(
          setBusinessEventPlanFilter({
            ...filter,
            status: filter.status.filter((status) => status !== id),
          }),
        );
        return;
      }
      dispatch(
        setBusinessEventPlanFilter({
          ...filter,
          status: [...filter.status, id],
        }),
      );
    },
    [filter, dispatch],
  );

  const handleChangeMonth = useCallback(
    (selected: string) => {
      if (filter.month === selected) {
        dispatch(setBusinessEventPlanFilter({ ...filter, month: '' }));
        return;
      }
      dispatch(setBusinessEventPlanFilter({ ...filter, month: selected }));
    },
    [filter, dispatch],
  );

  const handleRefreshFilters = useCallback(() => {
    dispatch(
      setBusinessEventPlanFilter({ name: '', type: '', status: '', month: '' }),
    );
  }, [dispatch]);
  return {
    eventName,
    selectedEventType,
    selectedMonth,
    options,
    showedMonth,
    handleEventNameChange,
    handleChangeEventType,
    handleChangeEventStatus,
    handleChangeMonth,
    handleRefreshFilters,
  };
}
