import { useToast } from '@chakra-ui/react';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import {
  closeAlertDialog,
  closePaymentDetailsDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { usePaymentMutations, usePayments } from 'hooks/usePayment';
import moment from 'moment';
import { useGetEntity } from 'queries/entity';
import useGetEvent from 'queries/event';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IClient, IClientContact, IPayment, IQuote } from 'services/@types';

export const usePaymentDetails = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const { paymentDetailsDialog } = useSelector(
    (state: RootState) => state.dialogs,
  );
  const business = useMyBusiness();
  const [currentPaymentProps, setCurrentPaymentProps] =
    useState<Partial<IPayment>>();

  const { data: event } = useGetEvent(paymentDetailsDialog?.item?.eventId);
  const { data: quote } = useGetEntity<IQuote>(
    'quote',
    paymentDetailsDialog?.item?.quoteId,
  );
  const { data: payments } = usePayments({
    quoteId: paymentDetailsDialog?.item?.quoteId,
    eventId: paymentDetailsDialog?.item?.eventId,
    businessId: business?.id,
    clientId: paymentDetailsDialog?.item?.clientId,
    supplierId: paymentDetailsDialog?.item?.supplierId,
    noSupplier: !paymentDetailsDialog?.item?.supplierId,
  });
  console.log(
    `payment details dialog`,
    JSON.stringify(paymentDetailsDialog),
    payments,
  );

  const {
    createPayment: {
      mutateAsync: createPaymentAsync,
      isPending: creatingPayment,
    },
    updatePayment: {
      mutateAsync: updatePaymentAsync,
      isPending: savingPayment,
    },
  } = usePaymentMutations();

  const onClose = useCallback(() => {
    dispatch(closePaymentDetailsDialog());
    setCurrentPaymentProps(undefined);
  }, [dispatch]);

  const pendingPayments = useMemo(
    () => payments?.results.filter((payment) => payment.isPaid === false),
    [payments],
  );
  const paidPayments = useMemo(
    () => payments?.results.filter((payment) => payment.isPaid === true),
    [payments],
  );

  const onUpdatePayment = useCallback(
    async (paymentId: string) => {
      // check if the paidAmount is less than the dueAmount
      const payment = payments?.results.find(
        (payment) => payment.id === paymentId,
      );
      if (
        currentPaymentProps?.paidAmount &&
        currentPaymentProps?.paidAmount < payment?.amount &&
        currentPaymentProps?.isPaid &&
        !payment.isPaid
      ) {
        dispatch(
          openAlertDialog({
            title: t(
              'event_finance.payment_details_modal.paid_amount_less_than_due_amount',
            ),
            onConfirm: async () => {
              await createPaymentAsync({
                ...currentPaymentProps,
                paymentIndex: payments?.results.length, // this will be increasing the payments index by 1
                amount: payment?.amount - currentPaymentProps?.paidAmount,
                clients: currentPaymentProps?.clients.map((c) => ({
                  client: (c.client as IClient).id,
                  contacts: c.contacts?.map(
                    (contact) => (contact as IClientContact).id,
                  ),
                })),
                isPaid: false,
                paidAmount: 0,
              });
              toast({
                title: t(
                  'event_finance.payment_details_modal.new_payment_created',
                ),
                status: 'success',
                variant: 'main',
              });
              dispatch(closeAlertDialog());
              return;
            },
          }),
        );
      }
      const paymentUpdates = { ...currentPaymentProps };
      delete paymentUpdates.id;
      delete paymentUpdates.createdAt;
      delete paymentUpdates.updatedAt;
      delete paymentUpdates.quoteId;
      delete paymentUpdates.businessId;
      delete paymentUpdates.clientId;
      delete paymentUpdates.supplierId;
      delete paymentUpdates.paymentIndex;

      await updatePaymentAsync({
        paymentId,
        updates: {
          ...paymentUpdates,
          clients: currentPaymentProps?.clients?.map((c) => ({
            client: (c.client as IClient).id,
            contacts: c.contacts?.map(
              (contact) => (contact as IClientContact).id,
            ),
          })),
          //  check if its paid, add paymentDate field if so
          ...(currentPaymentProps?.isPaid &&
            !payment?.isPaid && {
              paymentDate: moment().toDate(),
            }),
        },
      });
      toast({
        title: t('event_finance.payment_details_modal.payment_info_updated'),
        status: 'success',
        variant: 'main',
      });
    },
    [
      payments?.results,
      currentPaymentProps,
      updatePaymentAsync,
      toast,
      t,
      dispatch,
      createPaymentAsync,
    ],
  );

  return {
    currentPaymentProps,
    setCurrentPaymentProps,
    event,
    quote,
    savingPayment,
    payments,
    paymentDetailsDialog,
    onClose,
    pendingPayments,
    paidPayments,
    onUpdatePayment,
  };
};
