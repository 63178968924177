import { Button, Divider, Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import SelectClients from 'views/main/managment/events/createUpdateEvent/components/SelectClients';
import CreateContractDetails from './components/CreateContractDetails';
import CreateContractHeader from './components/CreateContractHeader';
import CreateContractParagraphs from './components/CreateContractParagraphs';
import CreateContractResipient from './components/CreateContractResipient';
import SaveAsTemplateDialog from './components/SaveAsTemplateDialog';
import useContractForm from './hooks/useContractForm';

export default function CreateContract() {
  const { t } = useTranslation();
  const {
    event,
    handleOpenEventDialog,
    contract,
    selectedCurrency,
    selectedLanguage,
    handleChange,
    showedCurrency,
    showedLanguage,
    radioValue,
    setRadioValue,
    selectedEventType,
    showedEventType,
    handleChangeEventType,
    currencySymbol,
    // displayingSentTo,
    // handleSelectSentTo,
    // currentSentTo,
    // handleChangeCurrentSentTo,
    // handleRemoveSentToItem,
    handleChangeCurrency,
    addRecipient,
    handleChangeRecipient,
    handleRemoveRecipient,
    handleAddFile,
    clearFile,
    loading,
    fileInputRef,
    handleCreateContract,
    handleAddQuoteFile,
    userId,
    handleSaveAsTemplate,
    isOpenSaveAsTemplate,
    onCloseSaveAsTemplate,
    handleOpenQuoteDialog,
    selectedQuote,
    handleOpenClientDialog,
    setClient,
    handleGoToPreview,
    error,
    setError,
  } = useContractForm();
  return (
    <Flex
      w="100%"
      pt={{ base: '200px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '57px' }}
      overflow="auto"
      flexDirection="column"
      gap={4}>
      <Card gap="36px" position="relative">
        <CreateContractHeader
          handleGoToPreview={handleGoToPreview}
          event={event}
          handleOpenEventDialog={handleOpenEventDialog}
          contract={contract}
          selectedCurrency={selectedCurrency}
          selectedLanguage={selectedLanguage}
          handleChange={handleChange}
          showedCurrency={showedCurrency}
          showedLanguage={showedLanguage}
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          handleChangeCurrency={handleChangeCurrency}
          handleAddQuoteFile={handleAddQuoteFile}
          clearFile={clearFile}
          userId={userId}
          handleOpenQuoteDialog={handleOpenQuoteDialog}
          selectedQuote={selectedQuote}
          selectedEventType={selectedEventType}
          showedEventType={showedEventType}
          handleChangeEventType={handleChangeEventType}
        />
        <Divider />
        <SelectClients
          handleOpenClientDialog={handleOpenClientDialog}
          handleChange={handleChange}
          clients={contract?.clients}
          hasError={false}
          setClient={setClient}
        />
        <Divider />
        <CreateContractParagraphs
          contract={contract}
          handleChange={handleChange}
        />
        <Divider />
        <CreateContractDetails
          contract={contract}
          handleChange={handleChange}
          currencySymbol={currencySymbol}
          // displayingSentTo={displayingSentTo}
          // handleSelectSentTo={handleSelectSentTo}
          // currentSentTo={currentSentTo}
          // handleChangeCurrentSentTo={handleChangeCurrentSentTo}
          // handleRemoveSentToItem={handleRemoveSentToItem}
          fileInputRef={fileInputRef}
          handleAddFile={handleAddFile}
          clearFile={clearFile}
          error={error}
          setError={setError}
        />
        <CreateContractResipient
          addRecipient={addRecipient}
          contract={contract}
          handleChangeRecipient={handleChangeRecipient}
          handleRemoveRecipient={handleRemoveRecipient}
        />
        <Flex
          justifyContent="flex-end"
          gap="16px"
          flexDirection={{ base: 'column', md: 'row' }}>
          <Button
            w={{ base: '100%', md: '240px' }}
            variant="h2outlined"
            isLoading={loading}
            onClick={handleSaveAsTemplate}>
            {t('create_contract.save_as_template')}
          </Button>
          <Button
            w={{ base: '100%', md: '240px' }}
            variant="h2outlinedGrey"
            isLoading={loading}
            onClick={() =>
              handleCreateContract({ isDraft: true, isTemplate: false })
            }>
            {t('create_contract.save_as_draft')}
          </Button>
          <Button
            isLoading={loading}
            w={{ base: '100%', md: '240px' }}
            variant="h1cta"
            isDisabled={error?.length > 0}
            onClick={() =>
              handleCreateContract({
                status: 'sent',
                isDraft: false,
                isTemplate: false,
              })
            }>
            {t('create_contract.send_contract')}
          </Button>
        </Flex>
      </Card>
      <SaveAsTemplateDialog
        contract={contract}
        isOpen={isOpenSaveAsTemplate}
        onClose={onCloseSaveAsTemplate}
        handleCreateContract={handleCreateContract}
      />
    </Flex>
  );
}
