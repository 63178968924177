import { Flex, Hide, Icon, Text, VStack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IoTriangleSharp } from 'react-icons/io5';
import { IQuote } from 'services/@types';
import QuoteListItem from './QuoteListItem';

const headers = ['date', 'event_name', 'amount', 'customer', ''];

export default function QuoteList(props: {
  quotes: IQuote[];
  sortBy: string;
  handleSort: (header: 'date' | 'event_name' | 'amount' | 'customer') => void;
  handleFetchNextPage: () => void;
}) {
  const { quotes, sortBy, handleSort, handleFetchNextPage } = props;
  const { t } = useTranslation();

  const handleDetectEndOfScroll = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight) {
      handleFetchNextPage();
    }
  }, [handleFetchNextPage]);

  return (
    <VStack w="100%" spacing={0} align="stretch" overflowY="hidden">
      <Hide below="md">
        <Flex py={4} px={6} bg="white">
          {headers.map((header, index) => (
            <Flex
              key={header}
              flex={index === headers.length - 1 ? '0 0 60px' : 1}
              align="center"
              fontSize="14px"
              fontWeight={sortBy === header ? '600' : '400'}
              color="#00000099">
              {header ? (
                <Text
                  onClick={() =>
                    handleSort(
                      header as 'date' | 'event_name' | 'amount' | 'customer',
                    )
                  }
                  cursor={header && 'pointer'}>
                  {t(`quote_list.${header.toLowerCase()}`)}
                </Text>
              ) : (
                ''
              )}
              {header && (
                <Icon
                  as={IoTriangleSharp}
                  color="#93C3B3"
                  h="10px"
                  ml={2}
                  transition="all 0.2s ease-in-out"
                  transform={
                    sortBy === header ? 'rotate(60deg)' : 'rotate(0deg)'
                  }
                />
              )}
            </Flex>
          ))}
        </Flex>
      </Hide>
      {/* Rows */}
      <VStack
        spacing={0}
        align="stretch"
        overflowY="auto"
        onScroll={handleDetectEndOfScroll}>
        {quotes.map((quote) => (
          <QuoteListItem key={quote.id} quote={quote} />
        ))}
      </VStack>
    </VStack>
  );
}
