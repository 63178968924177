import {
  Divider,
  Flex,
  Hide,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';

import CalendarIcon from 'assets/img/quote/greenCalendar.png';
import InputDateField from 'components/fields/InputDateField';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePieChart } from 'react-icons/ai';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { IQuote } from 'services/@types';
import { currency } from 'variables/currency';
import { useGetBusinessPreferences } from 'queries/businessPreferences';

const discountTypes = [
  { label: 'create_quote.percentage', value: 'percentage' },
  { label: 'create_quote.fixed', value: 'fixed' },
];

export default function PaymentDetails({
  newQuote,
  handleIncreasePaymentQuantity,
  handleDecreasePaymentQuantity,
  setNewQuote,
  currencyIcon,
  error,
  handleChangePaymentAmount,
}: {
  newQuote: IQuote;
  handleIncreasePaymentQuantity: () => void;
  handleDecreasePaymentQuantity: () => void;
  setNewQuote: React.Dispatch<React.SetStateAction<IQuote>>;
  currencyIcon: string;
  error: {
    totalPayment: boolean;
  };
  handleChangePaymentAmount: (e: any, index: number) => void;
}) {
  const { data: businessPreferences } = useGetBusinessPreferences();
  const { t } = useTranslation();
  const textColorPrimary = useColorModeValue('primary.100', 'white');

  const showedOptions = useMemo(() => {
    if (newQuote.generalPaymentDetails?.discountType === 'percentage') {
      return '%';
    }

    if (newQuote.generalPaymentDetails?.discountType === 'fixed') {
      return currency.find((c) => c.value === newQuote?.currency)?.symbol;
    }

    return '';
  }, [newQuote.generalPaymentDetails?.discountType, newQuote?.currency]);

  const selectedOptions = useMemo(() => {
    const option = discountTypes.find(
      (t) => t.value === newQuote.generalPaymentDetails?.discountType,
    );

    return option ? [option] : [];
  }, [newQuote.generalPaymentDetails?.discountType]);

  return (
    <Flex direction="column" gap="12px">
      <Text variant="createQuoteTitle">
        {t('create_quote.general_payment_details')}
      </Text>
      <InputField
        label={t('create_quote.total')}
        placeholder={t('create_quote.total')}
        value={newQuote.generalPaymentDetails?.total}
        type="number"
        onChange={(e: any) =>
          setNewQuote((prev) => ({
            ...prev,
            generalPaymentDetails: {
              ...prev.generalPaymentDetails,
              total: parseInt(e.target.value),
            },
          }))
        }
        iconLeft={<Text variant="currency">{currencyIcon}</Text>}
      />
      <InputField
        label={t('create_quote.discount')}
        placeholder={t('create_quote.discount')}
        value={newQuote.generalPaymentDetails?.discount}
        type="number"
        onChange={(e: any) =>
          setNewQuote((prev) => ({
            ...prev,
            generalPaymentDetails: {
              ...prev.generalPaymentDetails,
              discount: parseInt(e.target.value),
            },
          }))
        }
        iconPointerEvents="all"
        iconRight={
          <Flex alignItems="center">
            <Divider w="1px" h="36px" bg="#EBEDF0" />
            <DropdownField
              m="0 5px"
              w="40px"
              options={discountTypes}
              menuButton={{
                borderColor: 'transparent',
                bg: 'transparent',
                _active: { borderColor: 'transparent' },
                _hover: { borderColor: 'transparent' },
              }}
              downIconProps={{ mx: '5px' }}
              closeOnSelect
              showedOptions={showedOptions}
              selectedOptions={selectedOptions}
              onSelected={(e: any) => {
                console.log(e);
                setNewQuote((prev) => ({
                  ...prev,
                  generalPaymentDetails: {
                    ...prev.generalPaymentDetails,
                    discountType: e.value,
                  },
                }));
              }}
            />
          </Flex>
        }
      />
      <InputField
        label={t('create_quote.total_including')}
        placeholder={t('create_quote.total_including')}
        value={newQuote.generalPaymentDetails?.totalIncludingDiscount}
        type="number"
        onChange={(e: any) =>
          setNewQuote((prev) => ({
            ...prev,
            generalPaymentDetails: {
              ...prev.generalPaymentDetails,
              totalIncludingDiscount: parseInt(e.target.value),
            },
          }))
        }
        iconLeft={<Text variant="currency">{currencyIcon}</Text>}
      />
      <InputField
        label={t('quote_preview.vat', {
          percentage: businessPreferences?.vatPercent,
        })}
        placeholder={t('quote_preview.vat', {
          percentage: businessPreferences?.vatPercent,
        })}
        value={newQuote.generalPaymentDetails?.vat}
        isDisabled={true}
        type="number"
        iconLeft={<Text variant="currency">{currencyIcon}</Text>}
      />
      <InputField
        label={t('create_quote.total_payment')}
        placeholder={t('create_quote.total_payment')}
        value={newQuote.generalPaymentDetails?.totalPayment}
        type="number"
        onChange={(e: any) =>
          setNewQuote((prev) => ({
            ...prev,
            generalPaymentDetails: {
              ...prev.generalPaymentDetails,
              totalPayment: parseInt(e.target.value),
            },
          }))
        }
        borderColor={error.totalPayment ? 'error.100' : 'gray.200'}
        iconLeft={<Text variant="currency">{currencyIcon}</Text>}
      />
      <Text variant="createQuoteTitle">
        {t('create_quote.payment_in_installments')}
      </Text>
      <Hide below="md">
        <Flex direction="column" gap="4px">
          <Text
            fontSize="sm"
            color={textColorPrimary}
            fontWeight="notmal"
            ms="2px">
            {t('create_quote.quantity_of_installments')}
          </Text>
          <HStack gap={4} alignItems="center">
            <IconButton
              icon={<Icon as={FaMinus} color="#465D55" />}
              aria-label="Minus"
              bg="#93C3B333"
              borderRadius="50%"
              w="32px"
              h="32px"
              minW="32px"
              opacity={0.5}
              onClick={handleDecreasePaymentQuantity}
              disabled={newQuote?.paymentInInstallments?.quantity === 1}
            />
            <InputField
              type="number"
              textAlign="center"
              min={0}
              value={newQuote?.paymentInInstallments?.quantity}
            />
            <IconButton
              icon={<Icon as={FaPlus} color="#465D55" />}
              aria-label="Plus"
              bg="#93C3B333"
              borderRadius="50%"
              w="32px"
              h="32px"
              minW="32px"
              onClick={handleIncreasePaymentQuantity}
            />
          </HStack>
        </Flex>
      </Hide>
      {newQuote.paymentInInstallments?.installments?.map((payment, index) => (
        <VStack key={index} alignItems="start">
          <HStack>
            <Icon as={AiOutlinePieChart} color="#AA915D" />
            <Text variant="createQuote1Of1Payment">
              {t('create_quote.1_of_1_payment', {
                number: index + 1,
                total: newQuote?.paymentInInstallments?.installments?.length,
              })}
            </Text>
          </HStack>
          <InputField
            w="100%"
            type="number"
            name="amount"
            min={0}
            label={t('create_quote.amount')}
            placeholder={t('create_quote.amount')}
            value={payment.amount}
            onChange={(e: any) => {
              handleChangePaymentAmount(e, index);
            }}
            iconLeft={<Text variant="currency">{currencyIcon}</Text>}
          />
          <InputDateField
            containerStyles={{ flex: '1', w: { base: '100%' } }}
            buttonStyles={{
              w: { base: '100%' },
              maxW: { base: '100%' },
              borderColor: payment.dueDate ? '#0000001A' : 'error.100',
              justifyContent: 'space-between',
            }}
            value={payment.dueDate}
            imgSrc={CalendarIcon}
            onChange={(e: Date) =>
              setNewQuote((prev) => ({
                ...prev,
                paymentInInstallments: {
                  ...prev.paymentInInstallments,
                  installments: newQuote.paymentInInstallments.installments.map(
                    (p, key) => (key === index ? { ...p, dueDate: e } : p),
                  ),
                },
              }))
            }
            imgStyles={{
              bg: 'transparent',
            }}
          />
        </VStack>
      ))}
    </Flex>
  );
}
