import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { BusinessEventPlan } from 'services/@types';
import BusinessEventPlanService from 'services/businessEventPlan.api';

export interface BusinessEventPlanState {
  businessEventPlan: BusinessEventPlan | null;
  businessEventPlans: BusinessEventPlan[];
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
  filter: {
    name: string;
    status: string[];
    type: string;
    month: string;
  };
  loading: boolean;
  error: string | null;
}

const initialState: BusinessEventPlanState = {
  businessEventPlan: null,
  businessEventPlans: [],
  pagination: {
    page: 1,
    limit: 100,
    totalPages: 1,
    totalResults: 0,
  },
  filter: {
    name: '',
    status: [],
    type: '',
    month: '',
  },
  loading: false,
  error: null,
};

export const fetchBusinessEventPlans = createAsyncThunk(
  'businessEventPlans/fetchBusinessEventPlans',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await BusinessEventPlanService.getBusinessEventPlans(
        params,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const businessEventPlanSlice = createSlice({
  name: 'businessEventPlan',
  initialState,
  reducers: {
    setBusinessEventPlan: (state, action) => {
      state.businessEventPlan = action.payload;
    },
    clearBusinessEventPlan: (state) => {
      state.businessEventPlan = null;
    },
    setBusinessEventPlanFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessEventPlans.fulfilled, (state, action) => {
      state.businessEventPlans = action.payload.results;
      state.pagination = {
        page: action.payload.page,
        limit: action.payload.limit,
        totalPages: action.payload.totalPages,
        totalResults: action.payload.totalResults,
      };
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchBusinessEventPlans.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchBusinessEventPlans.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {
  setBusinessEventPlan,
  clearBusinessEventPlan,
  setBusinessEventPlanFilter,
} = businessEventPlanSlice.actions;
export const useBusinessEventPlanSelector = () =>
  useSelector((state: RootState) => state.businessEventPlan);
export default businessEventPlanSlice.reducer;
