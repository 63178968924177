import {
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import NoEventsImg from 'assets/svg/event/not-found.svg';
import Card from 'components/card/Card';
import { RootState } from 'contexts/redux/store';
import {
  getActiveTasks,
  getCompletedTasks,
} from 'contexts/redux/task/taskSlice';
import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useAppNavigate } from 'hooks/useAppNavigate';
import { useParams } from 'react-router-dom';
import EventService from '../../../../services/event.api';
import TaskCard from '../../dashboard/components/TaskCard';
import MeetingService from 'services/meeting.api';
import MeetingCard from './components/MeetingCard';
import { IMeeting } from 'services/@types';
const NoTask = () => {
  return <Image w="96px" h="96px" src={NoEventsImg} alt="" />;
};
export default function TaskHistory() {
  const { t, i18n } = useTranslation();
  const { appNavigate: navigate } = useAppNavigate();
  const params = useParams();
  const dispatch = useDispatch<any>();
  const { activeTasks, completedTasks } = useSelector(
    (state: RootState) => state.task,
  );

  const eventResult = useQuery({
    queryKey: [`getEvent-${params?.id}`],
    queryFn: () => EventService.getEvent(params?.id),
    enabled: !!params?.id,
  });

  useQuery({
    queryKey: [`getEventActiveTasks-${params?.id}`],
    queryFn: () =>
      dispatch(getActiveTasks({ event: params?.id, status: 'pending' })),
    enabled: !!params?.id,
  });

  useQuery({
    queryKey: [`getEventCompletedTasks-${params?.id}`],
    queryFn: () =>
      dispatch(getCompletedTasks({ event: params?.id, status: 'completed' })),
    enabled: !!params?.id,
  });

  const todayMeetings = useQuery({
    queryKey: ['todayMeetings', params?.id],
    queryFn: async () =>
      await MeetingService.getMeetings({
        eventId: params?.id,
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
      }),
    enabled: !!params?.id,
  });

  const eventData = useMemo(() => eventResult?.data, [eventResult?.data]);

  const date = useMemo(() => {
    if (eventData?.dateAndTime) {
      return format(new Date(eventData?.dateAndTime), 'dd.MM.yyyy', {
        locale: i18n.language.startsWith('he') ? he : enGB,
      });
    }
    return '';
  }, [eventData?.dateAndTime, i18n.language]);

  return (
    <Flex
      w="100%"
      pt={{ base: '190px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '0' }}
      flexDirection="column"
      gap={4}>
      <Card gap={5} h="calc(100vh - 170px)" overflowY="auto">
        <HStack justifyContent="space-between" align="start">
          <VStack align="flex-start">
            <Text variant="taskHistoryTitle">{eventData?.name}</Text>
            <Text variant="taskHistoryType">{eventData?.type}</Text>
            <Text variant="taskHistoryDate">{date}</Text>
          </VStack>
          <Icon
            as={FiX}
            w="16px"
            h="16px"
            cursor="pointer"
            onClick={() => navigate(-1 as any)}
          />
        </HStack>
        <VStack align="flex-start">
          <Text variant="eventCardTitle">{t('tasks.history.active')}</Text>
        </VStack>
        {todayMeetings.data?.results.map((meeting: IMeeting) => (
          <MeetingCard key={meeting.id} meeting={meeting} />
        ))}
        {activeTasks?.map((task) => (
          <TaskCard key={task.id} task={task} />
        ))}
        {activeTasks?.length === 0 &&
          todayMeetings.data?.results.length === 0 && (
            <VStack gap={3}>
              <NoTask />
              <Text variant="noCompletedTasksSubTitle">
                {t('tasks.history.no_active_tasks')}
              </Text>
              <Button
                onClick={() =>
                  navigate(`/main/tasks/create?eventId=${params?.id}`)
                }
                variant="h1cta">
                {t('tasks.create_new_task')}
              </Button>
            </VStack>
          )}

        <VStack align="flex-start">
          <Text variant="eventCardTitle">{t('tasks.history.completed')}</Text>
          {completedTasks?.map((task) => (
            <TaskCard key={task.id} task={task} isCompleted />
          ))}
        </VStack>
        {completedTasks?.length === 0 && (
          <VStack>
            <NoTask />
            <Text variant="noCompletedTasksTitle">
              {t('tasks.history.no_completed_tasks')}
            </Text>
            <Text variant="noCompletedTasksSubTitle">
              {t('tasks.history.no_completed_tasks_description')}
            </Text>
          </VStack>
        )}
      </Card>
    </Flex>
  );
}
