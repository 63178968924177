import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { closeDeletedExternalEventsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import EventCard from 'views/main/dashboard/components/EventCard';

export default function DeletedExternalEventsDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const { dialog, deletedExternalCalendarEvents } = useSelector(
    (state: RootState) => ({
      dialog: state.dialogs.deletedExternalEventsDialog,
      deletedExternalCalendarEvents:
        state.calendarEvent.deletedExternalCalendarEvents,
    }),
  );
  const handleClose = useCallback(() => {
    dispatch(closeDeletedExternalEventsDialog());
  }, [dispatch]);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px">
        <ModalBody p="2xl">
          <VStack w="100%" gap={2}>
            <HStack w="100%" justifyContent="space-between">
              <Text variant="cardSectionTitle" ml="30px">
                {t('dialogs.deleted_external_calendar_events.title')}
              </Text>
              <ModalCloseButton position="relative" top="0" />
            </HStack>
            <VStack w="100%" h="500px" overflowY="auto" gap={2}>
              {deletedExternalCalendarEvents.map((event: any, key: number) => (
                <EventCard event={event} key={key} isDelete isCalendarEvent />
              ))}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
