import { Flex, Hide, Text, useMediaQuery } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IPayment } from 'services/@types';
import ClientService from 'services/client.api';
import QuoteService from 'services/quote.api';
import PaymentsTableItemMenu from './PaymentsTableItemMenu';
import PaymentsTableItemMobile from './PaymentsTableItemMobile';
import EventService from 'services/event.api';

interface IProps {
  payment: IPayment;
}

export default function PaymentsTableItem(props: IProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();
  const { payment } = props;

  const { data: client } = useQuery({
    queryKey: [`getClient-${payment?.clientId}`],
    queryFn: () => ClientService.getClient(payment?.clientId),
    enabled: !!payment?.clientId,
  });

  const { data: quote } = useQuery({
    queryKey: [`getQuote-${payment?.quoteId}`],
    queryFn: () => QuoteService.getQuote(payment?.quoteId),
    enabled: !!payment?.quoteId,
  });

  const { data: event } = useQuery({
    queryKey: [`getEvent-${payment?.eventId}`],
    queryFn: () => EventService.getEvent(payment?.eventId),
    enabled: !!payment?.eventId,
  });

  const clientName = useMemo(() => {
    let name: string[] = [];
    if (client?.firstName) {
      name.push(client?.firstName);
    }
    if (client?.lastName) {
      name.push(client?.lastName);
    }
    return name.join(' ');
  }, [client]);

  const eventName = useMemo(() => {
    return event?.name || quote?.eventName || '';
  }, [event?.name, quote?.eventName]);

  if (isMobile) {
    return <PaymentsTableItemMobile payment={payment} eventName={eventName} />;
  }

  return (
    <Flex
      py={4}
      px={6}
      borderBottom="1px solid #E6E6E6"
      _hover={{ bg: 'gray.50' }}
      flexDirection="column">
      <Flex key={payment.id} justify="space-between" fontSize="14px">
        <Flex
          flex="1"
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'flex-start', md: 'space-between' }}
          gap={2}
          fontWeight="500">
          <Hide below="md">
            <Flex flex={1} fontWeight="700" align="center" noOfLines={1}>
              {payment?.dueDate && format(payment.dueDate, 'dd.MM.yy')}
            </Flex>
          </Hide>
          <Flex flex={1} fontWeight={{ base: '700', md: '500' }} align="center">
            <Text noOfLines={1} wordBreak={'break-word'}>
              {eventName}
            </Text>
          </Flex>
          <Flex flex={1} align="center">
            <Text noOfLines={1}>{payment?.amount}</Text>
          </Flex>
          <Flex flex={1} align="center">
            <Text noOfLines={1}>{payment?.paidAmount}</Text>
          </Flex>
          <Flex flex={1}>
            <Flex
              justify="center"
              align="center"
              borderRadius="10px"
              bg={
                payment?.paidAmount === payment?.amount
                  ? '#5FBF961A'
                  : '#EE5D501A'
              }
              px="10px"
              py="4px">
              <Text
                variant=""
                color={
                  payment?.paidAmount === payment?.amount
                    ? '#01B574'
                    : '#E31A1A'
                }>
                {t(
                  `finance_overview.${
                    payment?.paidAmount === payment?.amount ? 'paid' : 'due'
                  }`,
                )}
              </Text>
            </Flex>
          </Flex>
          {/* TODO: add payment method */}
          <Flex flex={1} align="center" noOfLines={1}>
            -
          </Flex>
          <Flex flex={1} align="center">
            <Text noOfLines={1} wordBreak={'break-word'}>
              {clientName}
            </Text>
          </Flex>
          <Hide below="md">
            <Flex flex="0 0 60px" justify="center" align="center">
              <PaymentsTableItemMenu
                paymentId={payment?.id}
                quoteId={payment?.quoteId}
                eventId={payment?.eventId}
              />
            </Flex>
          </Hide>
        </Flex>
        <Hide above="md">
          <Flex
            flex="0 1 100px"
            justify={{ base: 'space-between', md: 'flex-start' }}
            flexDirection="column">
            <PaymentsTableItemMenu
              paymentId={payment?.id}
              quoteId={payment?.quoteId}
              eventId={payment?.eventId}
            />
            <Flex>
              {payment?.dueDate && format(payment.dueDate, 'dd MMM, yyyy')}
            </Flex>
          </Flex>
        </Hide>
      </Flex>
      {/* TODO: add description */}
      {/* <Text variant="financeOverviewDescription">
        {t('finance_overview.description') + ':'}
      </Text> */}
    </Flex>
  );
}
