import { Collapse, Flex, Hide, Text } from '@chakra-ui/react';
import InputAutocompleteField from 'components/fields/InputAutocompleteField';
import InputField from 'components/fields/InputField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Business, IQuote } from 'services/@types';
import { AddProductModal } from 'views/main/managment/products/components/AddProductModal';
import useProductItem from '../hooks/useProductItem';
import PackageIcon from './PackageIcon';
import PackageProductInfo from './PackageProductInfo';
import { ProductVatCheckbox } from './ProductVatCheckbox';

export default function ProductItem({
  product,
  index,
  setNewQuote,
  products,
  handleSelectedProduct,
  currencyIcon,
  error,
  currency,
  handleChangeProductVatIncluded,
}: {
  product: any;
  index: number;
  setNewQuote: React.Dispatch<React.SetStateAction<IQuote>>;
  products: { value: string; label: string }[];
  handleSelectedProduct: (e: any, index: number) => void;
  currencyIcon: string;
  error: {
    products: boolean;
    totalPayment: boolean;
  };
  currency: string;
  handleChangeProductVatIncluded: (e: any, index: number) => void;
}) {
  const { t } = useTranslation();
  const {
    showOptions,
    isPackage,
    handleChangeDescription,
    onOptionsRendered,
    isAddProductOpen,
    onOpenAddProduct,
    currentEditableProduct,
    handleAddProductClosed,
    isPackageInfoVisible,
    handlePackageInfoVisibility,
    productData,
    handleChangeProductQuantity,
    handleChangeProductAmount,
  } = useProductItem({ product, setNewQuote, index, currency });

  return (
    <Flex flexDirection="column" gap="16px">
      <Flex wrap="wrap" gap="12px" width="100%" alignItems="end">
        {showOptions && (
          <Hide above="md">
            <ProductVatCheckbox
              product={product}
              index={index}
              setNewQuote={setNewQuote}
              handleChangeProductVatIncluded={handleChangeProductVatIncluded}
            />
          </Hide>
        )}
        <InputAutocompleteField
          label={t('create_quote.description')}
          placeholder={t('create_quote.description')}
          maxWidth={{ base: '100%', md: '256px' }}
          w={{ base: '100%', md: '256px' }}
          value={product.description}
          options={products}
          onSelected={(e: any) => {
            handleSelectedProduct(e, index);
          }}
          isIconWidthAuto={isPackage}
          borderColor={error.products ? 'error.100' : 'gray.200'}
          onChange={handleChangeDescription}
          onOptionsRendered={onOptionsRendered}
          iconLeft={<PackageIcon isPackage={isPackage} />}
        />
        {showOptions && (
          <InputField
            label={t('create_quote.quantity')}
            placeholder={t('create_quote.quantity')}
            maxWidth={{ base: '100%', md: '115px' }}
            w={{ base: '100%', md: '115px' }}
            type="number"
            min={1}
            value={product?.quantity}
            onChange={(e) => handleChangeProductQuantity(e, index)}
          />
        )}
        {showOptions && (
          <InputField
            label={t('create_quote.amount')}
            placeholder={t('create_quote.price')}
            maxWidth={{ base: '100%', md: '115px' }}
            w={{ base: '100%', md: '115px' }}
            type="number"
            value={product?.amount}
            onChange={(e) => handleChangeProductAmount(e, index)}
            iconLeft={<Text variant="currency">{currencyIcon}</Text>}
          />
        )}
        {showOptions && (
          <InputField
            label={t('create_quote.net')}
            placeholder={t('create_quote.net')}
            maxWidth={{ base: '100%', md: '125px' }}
            w={{ base: '100%', md: '125px' }}
            bg="#F6F6F6"
            asNumber={true}
            format="0,0.00"
            borderColor="#F6F6F6"
            value={product?.net}
          />
        )}
        {showOptions && (
          <InputField
            label={t('create_quote.vat')}
            placeholder={t('create_quote.vat')}
            maxWidth={{ base: '100%', md: '125px' }}
            w={{ base: '100%', md: '125px' }}
            bg="#F6F6F6"
            asNumber={true}
            format="0,0.00"
            borderColor="#F6F6F6"
            value={product?.vat}
          />
        )}
        {showOptions && (
          <InputField
            label={t('create_quote.gross')}
            placeholder={t('create_quote.gross')}
            maxWidth={{ base: '100%', md: '125px' }}
            w={{ base: '100%', md: '125px' }}
            bg="#F6F6F6"
            asNumber={true}
            format="0,0.00"
            borderColor="#F6F6F6"
            value={product?.gross}
          />
        )}
        {showOptions && (
          <Hide below="md">
            <ProductVatCheckbox
              product={product}
              index={index}
              setNewQuote={setNewQuote}
              handleChangeProductVatIncluded={handleChangeProductVatIncluded}
            />
          </Hide>
        )}
        {!showOptions && (
          <Flex alignItems="center" h="50px">
            <Text variant="createQuoteCheckboxText">
              {t('create_quote.no_product')}
            </Text>
            <Text
              variant="addNewProduct"
              cursor="pointer"
              onClick={onOpenAddProduct}>
              {t('create_quote.add_new_product')}
            </Text>
          </Flex>
        )}
        <AddProductModal
          isOpen={isAddProductOpen}
          editableProduct={currentEditableProduct}
          onFoundExistingProduct={(business: Business) => {
            console.log('found business ->', business);
          }}
          onClose={handleAddProductClosed}
        />
      </Flex>
      {isPackage && (
        <Collapse in={isPackageInfoVisible} animateOpacity>
          <Flex flexDirection="column" gap="16px">
            {productData?.subItems?.map((subItem, key) => (
              <PackageProductInfo productId={subItem as string} key={key} />
            ))}
          </Flex>
        </Collapse>
      )}
      {isPackage && (
        <Flex alignItems="center" justifyContent="center">
          <Text
            variant="addNewProduct"
            cursor="pointer"
            onClick={handlePackageInfoVisibility}>
            {t(
              isPackageInfoVisible
                ? 'create_quote.hide_package'
                : 'create_quote.show_package',
            )}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
