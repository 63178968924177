import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { IContract, PaginatedResults } from 'services/@types';
import ContractService from 'services/contract.api';
import { RootState } from '../store';
export interface ContractState {
  contracts: IContract[];
  currentContract: IContract | null;
  contract: IContract | null;
  editContract: IContract | null;
  contractPreview: Partial<IContract> | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: ContractState = {
  contracts: [],
  contract: null,
  loading: false,
  error: null,
  currentContract: null,
  editContract: null,
  contractPreview: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchContracts = createAsyncThunk(
  'contracts/fetchContracts',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await ContractService.getContracts(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchContract = createAsyncThunk(
  'contract/fetchContract',
  async (contractId: string, { rejectWithValue }) => {
    try {
      const response = await ContractService.getContract(contractId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createContract = createAsyncThunk(
  'contract/createContract',
  async (contract: IContract, { rejectWithValue }) => {
    try {
      // delete empty values
      Object.keys(contract).forEach((key) => {
        if (contract[key as keyof IContract] === '') {
          delete contract[key as keyof IContract];
        }
      });

      const newContract = await ContractService.createContract(contract);
      console.log('#Redux create contract ->', newContract);

      return newContract;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateContract = createAsyncThunk(
  'bussiness/updateContract',
  async (
    {
      contractId,
      updates,
    }: { contractId: string; updates: Partial<IContract> },
    { rejectWithValue },
  ) => {
    try {
      // Remove fields that are not allowed to be updated
      delete updates?.id;
      delete updates?.createdAt;
      delete updates?.updatedAt;

      // delete empty values
      Object.keys(updates).forEach((key) => {
        if (updates[key as keyof IContract] === '') {
          delete updates[key as keyof IContract];
        }
      });

      const response = await ContractService.updateContract(
        contractId,
        updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteContract = createAsyncThunk(
  'contract/deleteContract',
  async (contractId: string, { rejectWithValue }) => {
    try {
      await ContractService.deleteContract(contractId);
      return contractId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const shareContract = createAsyncThunk(
  'contracts/shareContract',
  async (
    { file, contractId }: { file: File; contractId: string },
    { rejectWithValue, getState },
  ) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('contractId', contractId);

      await ContractService.shareContract(formData);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
const contractSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setCurrentContract: (state, action: PayloadAction<IContract | null>) => {
      state.currentContract = action.payload;
    },
    setEditContract: (state, action: PayloadAction<IContract | null>) => {
      state.editContract = action.payload;
    },
    setContractPreview: (
      state,
      action: PayloadAction<Partial<IContract> | null>,
    ) => {
      state.contractPreview = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContracts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchContracts.fulfilled,
        (state, action: PayloadAction<PaginatedResults<IContract>>) => {
          state.contracts = action.payload.results;
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(fetchContracts.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchContract.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchContract.fulfilled,
        (state, action: PayloadAction<IContract>) => {
          state.contract = action.payload;
          state.loading = false;
        },
      )
      .addCase(fetchContract.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createContract.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createContract.fulfilled,
        (state, action: PayloadAction<IContract>) => {
          state.contract = action.payload;
          state.loading = false;
          state.contracts.push(action.payload);
        },
      )
      .addCase(createContract.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateContract.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateContract.fulfilled,
        (state, action: PayloadAction<IContract>) => {
          const index = state.contracts.findIndex(
            (contract) => contract.id === action.payload.id,
          );
          if (index !== -1) {
            state.contracts[index] = action.payload;
          }
          state.contract = action.payload;
          state.currentContract = action.payload;
          state.loading = false;
        },
      )
      .addCase(updateContract.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteContract.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteContract.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.contracts = state.contracts.filter(
            (contract) => contract.id !== action.payload,
          );
          state.currentContract = null;
          state.loading = false;
        },
      )
      .addCase(deleteContract.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setCurrentContract, setEditContract, setContractPreview } =
  contractSlice.actions;

export const useSelectedContract = () => {
  const client = useSelector(
    (state: RootState) => state.contracts.currentContract,
  );
  return { client };
};

export const useContractPreview = () => {
  const contractPreview = useSelector(
    (state: RootState) => state.contracts.contractPreview,
  );
  const contracts = useSelector(
    (state: RootState) => state.contracts.contracts,
  );
  return { contractPreview, contracts };
};

export default contractSlice.reducer;
