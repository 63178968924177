import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { closeDeletedExternalMeetingsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MeetingCard from 'views/main/dashboard/components/MeetingCard';

export default function DeletedExternalMeetingsDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const { dialog, deletedExternalCalendarMeetings } = useSelector(
    (state: RootState) => ({
      dialog: state.dialogs.deletedExternalMeetingsDialog,
      deletedExternalCalendarMeetings:
        state.calendarEvent.deletedExternalCalendarMeetings,
    }),
  );
  const handleClose = useCallback(() => {
    dispatch(closeDeletedExternalMeetingsDialog());
  }, [dispatch]);

  return (
    <Modal isOpen={!!dialog} onClose={handleClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent borderRadius="30px">
        <ModalBody p="2xl">
          <VStack w="100%" gap={2}>
            <HStack w="100%" justifyContent="space-between">
              <Text variant="cardSectionTitle" ml="30px">
                {t('dialogs.deleted_external_calendar_meetings.title')}
              </Text>
              <ModalCloseButton position="relative" top="0" />
            </HStack>

            <VStack w="100%" h="500px" overflowY="auto" gap={2}>
              {deletedExternalCalendarMeetings.map(
                (meeting: any, key: number) => (
                  <MeetingCard
                    key={key}
                    meeting={meeting}
                    isDelete
                    isExternalCalendarMeeting
                  />
                ),
              )}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
