import { useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { resetCurrentDocumentNumber } from 'contexts/redux/currentDocumentNumber/currentDocumentNumberSlice';
import {
  setClientDialog,
  setEventsDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { fetchProducts } from 'contexts/redux/product/productSlice';
import {
  createQuote,
  fetchQuote,
  setNewQuoteEventId,
  updateQuote,
} from 'contexts/redux/quote/quoteSlice';
import { RootState } from 'contexts/redux/store';
import { formatNumber } from 'helpers/formatNumber';
import { useAppNavigate } from 'hooks/useAppNavigate';
import {
  useGetBusinessPreferences,
  useUpdateBusinessPreferences,
} from 'queries/businessPreferences';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  IClient,
  IClientContact,
  IEvent,
  IQuote,
  Upload,
} from 'services/@types';
import EventService from 'services/event.api';
import UploadService from 'services/upload.api';
import { currency } from 'variables/currency';
import {
  addSelectedProduct,
  calculateProductsVat,
  calculateProductsVatByIndex,
  calculateTotal,
  calculateTotalIncludingDiscount,
  calculateTotalPayment,
  calculateTotalVat,
} from '../helpers/calculations';

const defaultQuote: IQuote = {
  eventDate: new Date(),
  eventType: 'other', // default event type
  currency: 'ILS',
  status: 'draft',
  products: [
    {
      description: '',
      amount: 1,
      quantity: 1,
      net: 0,
      vat: 0,
      gross: 0,
      isVatIncluded: false,
    },
  ],
  clients: [],
  generalPaymentDetails: {
    discountType: 'fixed',
  },
  paymentInInstallments: {
    quantity: 1,
    installments: [
      {
        amount: 0,
        dueDate: new Date(),
        isPaid: false,
      },
    ],
  },
};

export default function useCreateQuote() {
  const { mutate: updateBusinessPreferences } = useUpdateBusinessPreferences();
  const { loading, newQuoteEventId } = useSelector(
    (state: RootState) => state.quote,
  );
  const { data: businessPreferences } = useGetBusinessPreferences();

  const { products, currentDocumentNumber } = useSelector(
    (state: RootState) => ({
      products: state.product.products,
      currentDocumentNumber: state.currentDocumentNumber.currentDocumentNumber,
    }),
  );
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const params = useParams();
  const toast = useToast();
  const myUser = useMyUser();
  const { appNavigate: navigate, triggerChangesDetected } = useAppNavigate();
  const [event, setEvent] = useState<IEvent | null>(null);
  const [error, setError] = useState<{
    clients: boolean;
    products: boolean;
    totalPayment: boolean;
  }>({
    clients: false,
    products: false,
    totalPayment: false,
  });
  const [newQuote, setNewQuote] = useState<IQuote>(defaultQuote);
  const queryClient = useQueryClient();

  const [quoteNumber, setQuoteNumber] = useState<number>(0);
  // should increment the current document number in the global finance preferences
  const [
    updateInGlobalPreferencesOnSuccess,
    setUpdateInGlobalPreferencesOnSuccess,
  ] = useState<number | false>(false);

  // new fileds
  const [isAllProductsVatIncluded, setIsAllProductsVatIncluded] =
    useState<boolean>(false);

  // check if the payment in installments is equal to the total payment
  const isPaymentInInstallmentsCorrect = useMemo(() => {
    const totalPayment = newQuote?.generalPaymentDetails?.totalPayment || 0;
    const totalPaymentInInstallments =
      newQuote?.paymentInInstallments?.installments.reduce(
        (acc, curr) => acc + Number(curr.amount),
        0,
      );

    return totalPaymentInInstallments === totalPayment;
  }, [newQuote?.paymentInInstallments, newQuote?.generalPaymentDetails]);

  // Remaining amount or Amount exceeded
  const wrongAmount = useMemo(() => {
    const totalPayment = newQuote?.generalPaymentDetails?.totalPayment || 0;
    const totalPaymentInInstallments =
      newQuote?.paymentInInstallments?.installments.reduce(
        (acc, curr) => acc + Number(curr.amount),
        0,
      );
    return totalPayment - totalPaymentInInstallments;
  }, [
    newQuote?.paymentInInstallments?.installments,
    newQuote?.generalPaymentDetails?.totalPayment,
  ]);

  const isSaveButtonDisabled = useMemo(() => {
    return (
      error?.clients ||
      error?.products ||
      error?.totalPayment ||
      !isPaymentInInstallmentsCorrect ||
      loading
    );
  }, [
    error?.clients,
    error?.products,
    error?.totalPayment,
    isPaymentInInstallmentsCorrect,
    loading,
  ]);

  const currentEventType = useMemo(
    () => newQuote?.eventType || '',
    [newQuote?.eventType],
  );

  const selectedEventType = useMemo(
    () => (currentEventType ? [`event_types.${currentEventType}`] : []),
    [currentEventType],
  );

  const displayedProducts = useMemo(() => {
    return products
      .filter((p) => p.name !== 'your_first_product')
      .map((product) => ({
        value: product.id,
        label: product.name,
      }));
  }, [products, newQuote?.products]);

  const currencyIcon = useMemo(() => {
    const selected = currency.find((c) => c.value === newQuote?.currency);
    return selected ? selected.symbol : '';
  }, [newQuote?.currency]);

  const wrongAmountText = useMemo(() => {
    return wrongAmount > 0
      ? t('create_quote.remaining_amount', {
          amount: formatNumber(wrongAmount),
          currency: currencyIcon,
        })
      : t('create_quote.amount_exceeded', {
          amount: formatNumber(Math.abs(wrongAmount)),
          currency: currencyIcon,
        });
  }, [wrongAmount, t, currencyIcon]);

  const handleAddToLastPayment = useCallback(() => {
    setNewQuote((prev) => {
      const totalPayment = prev?.generalPaymentDetails?.totalPayment || 0;
      const paymentInInstallments =
        prev.paymentInInstallments?.installments || [];
      // total wiout last elemnt
      const totalPaymentInInstallments = paymentInInstallments.reduce(
        (acc, curr, index) => {
          if (index < paymentInInstallments.length - 1) {
            return acc + Number(curr.amount);
          }
          return acc;
        },
        0,
      );

      // add the remaining amount to the last payment
      paymentInInstallments[paymentInInstallments.length - 1].amount =
        totalPayment - totalPaymentInInstallments;

      return {
        ...prev,
        paymentInInstallments: {
          ...prev.paymentInInstallments,
          installments: paymentInInstallments,
        },
      };
    });
  }, []);

  const handleOpenClientDialog = useCallback(() => {
    console.log('create quote handle open client dialog');
    dispatch(
      setClientDialog({
        item: {
          chosenClients: newQuote?.clients || [],
        },
        onConfirm: (
          selection: { client: IClient; contacts: IClientContact[] }[],
        ) => {
          console.log('create quote on confirm', selection);
          if (error?.clients) {
            setError((prev) => ({ ...prev, clients: false }));
          }
          console.log('create quote new Quote clients', newQuote.clients);
          setNewQuote((prevstate) => {
            return {
              ...prevstate,
              clients: selection,
            };
          });
        },
      }),
    );
  }, [dispatch, error, newQuote?.clients]);

  const handleRemoveClient = useCallback(
    (client: IClient) => {
      setNewQuote((prev) => ({
        ...prev,
        clients: prev.clients.filter((c: any) => c?.id !== client.id),
      }));
    },
    [setNewQuote],
  );

  const handleChange = useCallback(
    (key: string, value: any) => {
      console.log('create quote handle change', key, value);
      triggerChangesDetected();
      setNewQuote((prev) => ({ ...prev, [key]: value }));
    },
    [triggerChangesDetected],
  );

  const handleAddProduct = useCallback(() => {
    setNewQuote((prev) => ({
      ...prev,
      products: [
        ...(prev?.products || []),
        {
          description: '',
          amount: 1,
          quantity: 1,
          net: 1,
          vat: 1 * businessPreferences?.vatValue,
          gross: 1 + 1 * businessPreferences?.vatValue,
          isVatIncluded: true,
        },
      ],
    }));
  }, [businessPreferences?.vatValue]);

  const setClient = useCallback(
    (client: { client: IClient; contacts: IClientContact[] }) => {
      setNewQuote((prev) => ({
        ...prev,
        clients: [...(prev?.clients || []), client],
      }));
    },
    [],
  );

  const handleValidateQuote = useCallback(() => {
    const error = {
      clients: newQuote?.clients.length === 0,
      products: newQuote?.products.some((p) => p.amount === 0),
      totalPayment: newQuote?.generalPaymentDetails?.totalPayment === 0,
    };

    setError(error);

    return Object.values(error).every((value) => value === false);
  }, [
    newQuote?.clients,
    newQuote?.products,
    newQuote?.generalPaymentDetails?.totalPayment,
  ]);

  const handleCreateQuote = useCallback(async () => {
    if (!handleValidateQuote()) return;

    if (params?.id) {
      const updatedQuote: any = {
        ...newQuote,
        clients: newQuote.clients.map(
          (c: { client: IClient; contacts: IClientContact[] | string[] }) => ({
            client: c.client.id,
            contacts: !c.contacts
              ? []
              : (c.contacts as IClientContact[]).map((contact) => contact.id),
          }),
        ),
      };
      delete updatedQuote.businessId;
      if (updatedQuote?.requestQuoteToken) {
        delete updatedQuote.requestQuoteToken;
      }
      if (updatedQuote?.id) {
        delete updatedQuote.id;
      }
      const response = await dispatch(
        updateQuote({ id: params.id, updates: updatedQuote }),
      );

      if (response.error) {
        toast({
          title: t('create_quote.error'),
          status: 'error',
          variant: 'error',
        });
        return;
      }

      toast({
        title: t('create_quote.quote_updated'),
        status: 'success',
        variant: 'main',
      });
      queryClient.invalidateQueries({
        queryKey: [`quoteInEventPage-${newQuote.eventId}`],
      });

      if (window?.history?.length > 1) {
        navigate(-1);
      } else {
        navigate('/main/dashboard');
      }
      return;
    }

    const newQuoteWithSenderId: any = { ...newQuote };
    newQuoteWithSenderId.quoteNumber = quoteNumber;

    if (!newQuoteWithSenderId.quoteNumber) {
      newQuoteWithSenderId.quoteNumber = Number(businessPreferences?.quote);
    }

    newQuoteWithSenderId.clients = newQuote.clients.map(
      (c: { client: IClient; contacts: IClientContact[] | string[] }) => ({
        client: c.client.id,
        contacts: !c.contacts
          ? []
          : (c.contacts as IClientContact[]).map((contact) => contact.id),
      }),
    );

    const response = await dispatch(createQuote(newQuoteWithSenderId));
    if (response.error) {
      toast({
        title: t('create_quote.error'),
        status: 'error',
        variant: 'error',
      });
      return;
    }

    toast({
      title: t('create_quote.quote_created'),
      status: 'success',
      variant: 'main',
    });
    queryClient.invalidateQueries({
      queryKey: [`quoteInEventPage-${newQuote.eventId}`],
    });
    if (updateInGlobalPreferencesOnSuccess || !quoteNumber) {
      updateBusinessPreferences({
        quote: Number(businessPreferences?.quote) + 1,
      });
      setUpdateInGlobalPreferencesOnSuccess(false);
    }
    navigate('/main/finance/quotes');
  }, [
    handleValidateQuote,
    params.id,
    newQuote,
    quoteNumber,
    dispatch,
    toast,
    t,
    queryClient,
    navigate,
    updateInGlobalPreferencesOnSuccess,
    updateBusinessPreferences,
    businessPreferences?.quote,
  ]);

  const handleChangeEventType = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_types.', '');

      if (selectedEventType.includes(selected)) {
        handleChange('eventType', '');
        return;
      }
      handleChange('eventType', formattedSelected);
    },
    [selectedEventType, handleChange],
  );

  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: IEvent) => {
          setEvent(e);

          setNewQuote((prev) => ({
            ...prev,
            eventId: e?.id,
            location: e?.location?.label,
            eventType: e?.type,
            eventDate: e?.dateAndTime,
            numberOfGuests: e?.numberOfGuests,
            eventName: e?.name,
          }));
        },
      }),
    );
  }, [dispatch]);

  const clearFile = useCallback(async () => {
    if (params?.id) {
      dispatch(updateQuote({ id: params.id, updates: { media: [] } }));
    } else {
      try {
        const [target, targetId, access, fileId] =
          newQuote?.media[0]?.url.split('/');
        await UploadService.deleteUpload({ target, targetId, access, fileId });
      } catch (error) {
        console.error('use clear file error ->', error);
      }
    }

    setNewQuote((prev) => ({
      ...prev,
      media: [],
    }));
  }, [newQuote?.media, params?.id, dispatch]);

  const handleChangeFile = useCallback(
    async (event: any) => {
      event.stopPropagation();
      const file = event?.target?.files[0];

      if (!file) return;

      try {
        const newImg: Upload = await UploadService.uploadFile(
          'user',
          myUser?.id,
          'private',
          file,
        );

        if (newImg) {
          setNewQuote((prev) => ({
            ...prev,
            media: [
              {
                url: newImg.filePath,
                type: 'document',
                displayName: newImg.fileName,
              },
            ],
          }));
        }
      } catch (error) {
        console.error('use upload file error ->', error);
      }
    },
    [myUser?.id],
  );

  const handleRemovePayment = useCallback(() => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev.paymentInInstallments,
        installments: prev.paymentInInstallments.installments.slice(0, -1),
      },
    }));
  }, []);

  const handleAddPayment = useCallback(() => {
    const total = newQuote.generalPaymentDetails?.totalPayment;
    const rest = newQuote.paymentInInstallments.installments.reduce(
      (acc, curr) => acc + Number(curr.amount),
      0,
    );
    const difference = total - rest;
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev.paymentInInstallments,
        quantity: prev.paymentInInstallments.quantity + 1,
        installments: [
          ...(prev?.paymentInInstallments?.installments || []),
          {
            amount: difference > 0 ? difference : 0,
            dueDate: new Date(),
            isPaid: false,
          },
        ],
      },
    }));
  }, [
    newQuote?.generalPaymentDetails?.totalPayment,
    newQuote?.paymentInInstallments?.installments,
  ]);

  const handleIncreasePaymentQuantity = useCallback(() => {
    handleAddPayment();
  }, [handleAddPayment]);

  const handleDecreasePaymentQuantity = useCallback(() => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev.paymentInInstallments,
        quantity: prev.paymentInInstallments.quantity - 1,
      },
    }));
    handleRemovePayment();
  }, [handleRemovePayment]);

  const handleSelectedProduct = useCallback(
    (e: any, index: number) => {
      const selectedProduct = products.find((p) => p.id === e);
      setNewQuote((prev) => ({
        ...prev,
        products: addSelectedProduct(
          prev.products,
          selectedProduct,
          index,
          businessPreferences?.vatValue,
        ),
      }));
    },
    [businessPreferences?.vatValue, products],
  );

  const handleChangePaymentAmount = useCallback((e: any, index: number) => {
    setNewQuote((prev) => ({
      ...prev,
      paymentInInstallments: {
        ...prev.paymentInInstallments,
        installments: prev?.paymentInInstallments?.installments.map((p, key) =>
          key === index
            ? { ...p, amount: e.target.value }
            : {
                ...p,
              },
        ),
      },
    }));
  }, []);

  const handleChangeAllProductsVatIncluded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsAllProductsVatIncluded(e.target.checked);
      setNewQuote((prev) => {
        const products = calculateProductsVat(
          prev.products,
          e.target.checked,
          businessPreferences?.vatValue,
        );
        return {
          ...prev,
          products,
        };
      });
    },
    [businessPreferences?.vatValue],
  );

  const handleChangeProductVatIncluded = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      setNewQuote((prev) => ({
        ...prev,
        products: calculateProductsVatByIndex(
          prev.products,
          index,
          e.target.checked,
          businessPreferences?.vatValue,
        ),
      }));
    },
    [businessPreferences?.vatValue],
  );

  useEffect(() => {
    dispatch(fetchProducts({}));
  }, [dispatch]);

  // handle total generalPaymentDetails
  useEffect(() => {
    setNewQuote((prev) => ({
      ...prev,
      generalPaymentDetails: {
        ...prev.generalPaymentDetails,
        total: calculateTotal(prev.products),
      },
    }));
  }, [newQuote.products, newQuote.currency]);

  // handle total including discount
  useEffect(() => {
    setNewQuote((prev) => ({
      ...prev,
      generalPaymentDetails: {
        ...prev.generalPaymentDetails,
        totalIncludingDiscount: calculateTotalIncludingDiscount(
          prev.generalPaymentDetails.discountType,
          prev.generalPaymentDetails.discount,
          prev.generalPaymentDetails.total,
        ),
      },
    }));
  }, [
    newQuote?.generalPaymentDetails?.discountType,
    newQuote?.generalPaymentDetails?.discount,
    newQuote?.generalPaymentDetails?.total,
  ]);

  // handle total including vat
  useEffect(() => {
    setNewQuote((prev) => ({
      ...prev,
      generalPaymentDetails: {
        ...prev.generalPaymentDetails,
        vat: calculateTotalVat(prev.products),
        totalPayment: calculateTotalPayment(
          prev.products,
          prev?.generalPaymentDetails?.totalIncludingDiscount,
        ),
      },
    }));
  }, [
    newQuote?.products,
    newQuote?.generalPaymentDetails?.totalIncludingDiscount,
  ]);

  useEffect(() => {
    if (params.id) {
      const getQuote = async () => {
        const response = await dispatch(fetchQuote(params.id));
        setNewQuote({ ...defaultQuote, ...response.payload });

        if (response.payload?.eventId) {
          const event = await EventService.getEvent(response.payload.eventId);
          if (event) {
            setEvent(event);
            setNewQuote((prev) => ({
              ...prev,
              location: event.location?.label,
              eventType: event.type,
              eventDate: event.dateAndTime,
              numberOfGuests: event.numberOfGuests,
              eventName: event.name,
            }));
          }
        }
      };
      getQuote();
    } else if (newQuoteEventId) {
      EventService.getEvent(newQuoteEventId).then((event) => {
        dispatch(setNewQuoteEventId(null));
        if (event) {
          setEvent(event);
          setNewQuote((prev) => ({
            ...prev,
            eventId: event.id,
            location: event.location?.label,
            eventType: event.type,
            eventDate: event.dateAndTime,
            numberOfGuests: event.numberOfGuests,
            eventName: event.name,
            vatPercent: businessPreferences?.vatPercent,
          }));
        }
      });
    }

    if (!params?.id) {
      if (currentDocumentNumber?.value) {
        setQuoteNumber(currentDocumentNumber?.value);
        if (!currentDocumentNumber?.onlyCurrent) {
          // if onlyCurrent is false, set it to global finance preferences
          if (businessPreferences?.quote !== currentDocumentNumber?.value) {
            updateBusinessPreferences({
              quote: currentDocumentNumber?.value,
            });
          }
          setUpdateInGlobalPreferencesOnSuccess(
            currentDocumentNumber?.value + 1,
          );
        }
        dispatch(resetCurrentDocumentNumber());
      }
    }
  }, [
    params.id,
    dispatch,
    newQuoteEventId,
    businessPreferences?.vatPercent,
    currentDocumentNumber,
    updateBusinessPreferences,
    updateInGlobalPreferencesOnSuccess,
    businessPreferences?.quote,
  ]);

  // observe error and clear error
  useEffect(() => {
    if (error.clients && newQuote.clients.length > 0) {
      setError((prev) => ({ ...prev, clients: false }));
    }

    if (error.products && newQuote.products.every((p) => p.amount !== 0)) {
      setError((prev) => ({ ...prev, products: false }));
    }

    if (
      error.totalPayment &&
      newQuote.generalPaymentDetails?.totalPayment !== 0
    ) {
      setError((prev) => ({ ...prev, totalPayment: false }));
    }
  }, [
    newQuote.clients,
    newQuote.products,
    newQuote.generalPaymentDetails?.totalPayment,
    error,
  ]);

  useEffect(() => {
    if (newQuote?.products?.length > 0) {
      setIsAllProductsVatIncluded(
        newQuote?.products?.every((p) => p?.isVatIncluded),
      );
    }
  }, [newQuote?.products]);

  return {
    newQuote,
    handleChange,
    handleAddProduct,
    handleChangeEventType,
    selectedEventType,
    handleOpenEventDialog,
    event,
    handleAddPayment,
    handleCreateQuote,
    handleIncreasePaymentQuantity,
    handleDecreasePaymentQuantity,
    setNewQuote,
    isLoading: loading,
    products: displayedProducts,
    handleSelectedProduct,
    handleChangeFile,
    clearFile,
    currencyIcon,
    error,
    handleChangePaymentAmount,
    handleChangeAllProductsVatIncluded,
    isAllProductsVatIncluded,
    handleChangeProductVatIncluded,
    handleOpenClientDialog,
    handleRemoveClient,
    setClient,
    isSaveButtonDisabled,
    isPaymentInInstallmentsCorrect,
    wrongAmount,
    wrongAmountText,
    handleAddToLastPayment,
  };
}
