import { formatNumber, formatToLocaleString } from 'helpers/formatNumber';
import { useGetIncomes } from 'hooks/usePayment';
import { useMemo, useState } from 'react';

export default function useIncomes() {
  const [incomesFilter, setIncomesFilter] = useState<{
    fromDate: Date;
    toDate: Date;
    range: string;
    search?: string;
  }>({
    fromDate: new Date(),
    toDate: new Date(),
    range: '',
    search: '',
  });

  const IncomesQueryParams = useMemo(() => {
    const params: Record<string, any> = {};
    if (incomesFilter.range) {
      params.range = incomesFilter.range;
    }
    if (incomesFilter.fromDate) {
      params.fromDate = incomesFilter.fromDate;
    }
    if (incomesFilter.toDate) {
      params.toDate = incomesFilter.toDate;
    }
    if (incomesFilter.search) {
      params.search = incomesFilter.search;
    }
    return params;
  }, [incomesFilter]);

  const { data: incomes } = useGetIncomes(IncomesQueryParams);

  const incomeCalcs = useMemo(() => {
    let expected = 0;
    let received = 0;
    let due = 0;
    incomes?.forEach((income) => {
      expected += income.amount;
      received += income.paidAmount;
      due += income.amount - income.paidAmount;
    });
    return {
      expected,
      received,
      due: due < 0 ? 0 : due,
    };
  }, [incomes]);

  const statsIncome = useMemo(
    () => [
      {
        title: 'finance_overview.expected',
        value: `₪${formatToLocaleString(incomeCalcs.expected)}`,
        bgColor: '#465D55',
      },
      {
        title: 'finance_overview.received',
        value: `₪${formatToLocaleString(incomeCalcs.received)}`,
        bgColor: 'linear-gradient(86.51deg, #93C3B3 40.99%, #86E6C6 94.26%)',
      },
      {
        title: 'finance_overview.due',
        value: `₪${formatToLocaleString(incomeCalcs.due)}`,
        bgColor: 'linear-gradient(86.51deg, #93C3B3 40.99%, #86E6C6 94.26%)',
      },
    ],
    [incomeCalcs],
  );

  return {
    incomes,
    statsIncome,
    incomesFilter,
    setIncomesFilter,
  };
}
